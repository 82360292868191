import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    code: "code",
    h1: "h1",
    h2: "h2",
    p: "p",
    pre: "pre",
    span: "span",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Reference: useRoute",
      description: "API Reference for the useRoute hook in Firebolt."
    }), "\n", _jsx(_components.h1, {
      children: "useRoute"
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "useRoute"
      }), " hook provides details about the current route and methods to redirect users to another location."]
    }), "\n", _jsx(_components.h2, {
      children: ".url"
    }), "\n", _jsxs(_components.p, {
      children: ["The base URL including any hash and query parameters, eg ", _jsx(_components.code, {
        children: "/blog/123?foo=bar#trains"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      children: ".pathname"
    }), "\n", _jsxs(_components.p, {
      children: ["The path of the current URL, eg ", _jsx(_components.code, {
        children: "/blog/123"
      })]
    }), "\n", _jsx(_components.h2, {
      children: ".hash"
    }), "\n", _jsxs(_components.p, {
      children: ["The hash of the current URL, eg ", _jsx(_components.code, {
        children: "#trains"
      })]
    }), "\n", _jsx(_components.h2, {
      children: ".params"
    }), "\n", _jsx(_components.p, {
      children: "An object that includes both search params and dynamic route segment values."
    }), "\n", _jsxs(_components.p, {
      children: ["Given the page ", _jsx(_components.code, {
        children: "routes/blog/$id.js"
      }), " and the url ", _jsx(_components.code, {
        children: "/blog/123?foo=bar"
      }), ", the params object would look like this:"]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "{"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "  id"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ": "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'123'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ","
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "  foo"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ": "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'bar'"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.h2, {
      children: ".search(key, value)"
    }), "\n", _jsxs(_components.p, {
      children: ["Updates search params in the URL. Components will render again with the updated ", _jsx(_components.code, {
        children: "params"
      }), " object."]
    }), "\n", _jsx(_components.h2, {
      children: ".push(href)"
    }), "\n", _jsx(_components.p, {
      children: "Performs client-side navigation, adding the new route."
    }), "\n", _jsx(_components.h2, {
      children: ".replace(href)"
    }), "\n", _jsx(_components.p, {
      children: "Performs client-side navigation, replacing the current route with the new route."
    }), "\n", _jsx(_components.h2, {
      children: ".back()"
    }), "\n", _jsx(_components.p, {
      children: "Navigate to the previous route in the history."
    }), "\n", _jsx(_components.h2, {
      children: ".forward()"
    }), "\n", _jsx(_components.p, {
      children: "Navigate to the next route in the history."
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
